import {
    SET_TYPED_COMMUNICATION_PROVIDER_LIST,
    SET_COMMUNICATION_PROVIDER_LIST,
    SET_COMMUNICATION_PROVIDER,
    SET_UPDATE_COMMUNICATION_PROVIDER,
    CLEAR_COMMUNICATION_PROVIDER
} from 'store/types';
import { LOGOUT_USER } from 'store/types';
import { CLEAR_CURRENT_EDIT_TEMPLATE } from 'store/types';

const initialState = {
    typedProvidersList: [],
    providers: null,
    currentProvider: {}
}

export default function communications(store = initialState, { type, payload }) {
    switch (type) {
        case SET_COMMUNICATION_PROVIDER:
        case SET_UPDATE_COMMUNICATION_PROVIDER:
            return {
                ...store, currentProvider: payload.data
            }
        case SET_TYPED_COMMUNICATION_PROVIDER_LIST:
            return {
                ...store, typedProvidersList: payload.communication_providers
            };
        case CLEAR_CURRENT_EDIT_TEMPLATE:
            return {
                ...store, typedProvidersList: []
            };
        case SET_COMMUNICATION_PROVIDER_LIST:
            return {
                ...store, providers: payload.data
            }
        case LOGOUT_USER:
            return {
                ...store,
                list: payload.defaultCommunicationsStore.list
            };
        case CLEAR_COMMUNICATION_PROVIDER:
            return {
                ...store,
                currentProvider: {}
            }
        default:
            return store;
    }
}
